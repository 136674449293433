<template>
  <div class="end mainWrap">
    <div class="endWrap p-0">
      <p class="text-center endMsg">申請が完了しました</p>
      <p class="text-center mb-4">申請番号： {{code}}</p>
      <div v-if="apptypeName === '大学講座寄付'">
        <p class="mb-0 msg">ステータスが「確認中」から「受領」になったことを確認してから、<br>作成した①〜②の書類を下記の宛先まで郵送ください。</p>
        <hr class="mt-1">
        <div class="mt-2">
          <p class="my-2 msg">
            ① システムからPDF出力した申請書一式
            <b-link
              @click="exportPdf"
              class="printBtn">
              PDF出力
            </b-link>
          </p>
          <p class="my-2 msg">② 所属機関承諾書（承諾者の押印済、原本）</p>
        </div>
        <div>
          <p class="mb-0 msg">※①の申請書はA4用紙にモノクロ・片面で印刷してください。</p>
          <p class="my-2 msg">※送付の際には、封筒おもてに「申請書在中」と朱記ください。</p>
        </div>
        <div class="mt-4 sendRuleWrap px-2 py-3">
          <div class="mb-0">
            <div class="endLabel mb-2 text-center mr-2">送付締切</div>
            <span>{{deadlineDate}}必着</span>
          </div>
          <div class="mb-0">
            <div class="endLabel mb-2 text-center mr-2">
              宛<span class="space"/>先</div>
              <span class="mr-2">〒541-0041</span>
              <span>大阪市中央区北浜4-5-33</span>
          </div>
          <p class="mb-0"><span class="endLabelSecond"></span>（公財）住友電工グループ社会貢献基金</p>
        </div>
      </div>
      <div v-else>
        <p class="mb-0 msg">事務局で確認の後、受領確認のメールが届きますので、ご確認ください。</p>
        <hr class="mt-1">
      </div>
      <div class="bottonArea flex flexCenter mt-5">
        <b-button
          class="bold"
          variant="primary"
          :to="`/top`">トップに戻る</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';

export default {
  name: 'gasApplicationEnd',
  components: {
  },
  data() {
    return {
      id: null,
      year: null,
      name: '',
    };
  },
  computed: {
    code() {
      return this.$store.state.gasApplicationInput.code;
    },
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    apptypeName() {
      return this.$store.state.gasApplicationForm.application_type_name;
    },
    apptypeFileName() {
      return `申請書(${this.apptypeName})`;
    },
    deadlineDate() {
      const thisYear = moment().format('YYYY');
      const limitDate = `${thisYear}-06-14`;
      moment.locale('ja', {
        weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
      });

      return moment(limitDate).format('YYYY年M月D日(ddd)');
    },
  },
  methods: {
    async exportPdf() {
      this.$store.dispatch('page/onWaiting');
      const params = {
        apptypeId: this.apptypeId,
      };
      const requireBlob = true;
      const response = await api.send('/api/application/export/mypdf', params, requireBlob)
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。お手数ですが、添付いただいたファイルのPDF変換をもう一度やり直すか、別の方法でPDFへの変換をお試しください。それでも解消しない場合は、事務局までお問い合わせください。');
        return;
      }
      const ymd = moment().format('YYYYMMDD');
      const fileName = `${ymd}_${this.apptypeFileName}.pdf`;
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  created() {
  },
};
</script>

<style scoped>
  .end{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .endWrap{
    width: 555px;
    padding: 30px;
  }

  .endMsg {
    font-size: 30px;
  }

  .endLabel {
    width: 70px;
    display: inline-block;
    background-color: #6E6E6E;
    color: #fff;
    font-weight: bold;
  }

  .endLabelSecond {
    width: 170px;
    display: inline-block;
  }

  .space {
    width: 15px;
    display: inline-block;
  }

  .sendRuleWrap {
    background-color: #F3F3F3;
  }

  .msg {
    padding-left: 25px;
  }

  .printBtn{
    margin-left: 10px;
    border: solid 1px;
    padding: 5px;
    border-radius: 6px;
    width: 100px;
    display: inline-block;
    font-size: 12px;
    text-align: center;
  }
  .printBtn:hover{
    text-decoration: none;
  }

</style>
